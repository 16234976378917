import React, {useState} from 'react';

import { FiMenu } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import logo from '../assets/whitelogo.webp'
import styled from 'styled-components'

export default function Navigation ({handleSectionClick}) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (event, sectionName) => {
    event.preventDefault();
    toggleNavigation();
    handleSectionClick(event, sectionName);
  };

  return (

    <>

      <OutsideWrapper>

      <StyledLogoDiv  isSmall isMobile>
        <img src={logo} alt='/' />
      </StyledLogoDiv>

      <Burger onClick={toggleNavigation} name='menu'>
        { isOpen ? <GrClose /> : <FiMenu />}
      </Burger>

      <NavWrapper isOpen={isOpen}>

      <StyledLogoDiv>
        <img src={logo} alt='/' />
      </StyledLogoDiv>

        <NavContent>
          <ul>
            <li><Link to='/' onClick={(event) => handleLinkClick(event, 'home')}>home</Link></li>
            <li><Link to='/' onClick={(event) => handleLinkClick(event, 'aboutme')}>about me</Link></li>
            <li><Link to='/' onClick={(event) => handleLinkClick(event, 'pricing')}>pricing</Link></li>
            <li><Link to='/' onClick={(event) => handleLinkClick(event, 'mywork')}>my work</Link></li>
            <li><Link to='/' onClick={(event) => handleLinkClick(event, 'contact')}>contact</Link></li>
          </ul>
        </NavContent>

      </NavWrapper>  

      </OutsideWrapper>

    </>

  )
}

const OutsideWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  padding: 15px 20px;
  background: transparent;
  z-index: 1000;

  ${({ theme }) => theme.mq.xl} {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 7.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
`

const StyledLogoDiv = styled.div`
  width: ${({ isSmall }) => (isSmall ? '150px' : '200px')};
  margin-top: 10px;
  left: 0;

  img {
    width: 100%;
  }

  ${({ theme }) => theme.mq.xl} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'initial')};
  }
`;

const Burger = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;

  svg {
    font-size: 20px;
    color: ${({ theme }) => theme.color.white};
  }

  ${({ theme }) => theme.mq.md}{
    svg {
      font-size: 30px;
      color: ${({ theme }) => theme.color.white};
    }
  }

  ${({ theme }) => theme.mq.xl}{
    display: none;
  }
`

const NavWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 50px;
  background: ${({ theme }) => theme.color.beige};
  align-items: center;

  ${({ theme }) => theme.mq.xl} {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    background-color: transparent;
    margin: 0 auto;
    padding: 15px 45px;
    align-items: flex-start;
  }
`

const NavContent = styled.nav`
  ul {
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      
      a {
        display: inline-block;
        padding: 20px;
        font-family: ${({ theme }) => theme.font.family.poppins};
        font-size: ${({ theme }) => theme.font.size.sm.paragraph};
        font-weight: 400;
        color: ${({ theme }) => theme.color.white};
        text-decoration: none;
      }

      &:last-child {
        border: 1px solid ${({ theme }) => theme.color.white};
        margin-top: 1rem;
        padding: 0rem 2.5rem;
      }
    }

    ${({ theme }) => theme.mq.md} {

      li {

        a {
          padding: 2rem 1rem;
          font-size: ${({ theme }) => theme.font.size.md.paragraph};
          }
        }
      }
    }

    ${({ theme }) => theme.mq.xl}{

      ul {

        li {
  
          a {
  
            &:hover {
              color: ${({theme}) => theme.color.darkBeige};
              transition: 0.5s;
            }
          }
  
          &:last-child {
            border: 1px solid ${({ theme }) => theme.color.white};
            margin-top: 0;
            padding: 0rem 2.5rem;
            transition: .5s;

            &:hover {
              background: ${({ theme }) => theme.color.darkBeige};
              border: 1px solid ${({ theme }) => theme.color.darkBeige};
            }

            a {
              &:hover {
                color: ${({theme}) => theme.color.white};
              transition: 0.5s;
              }
            }
          }
        }
      }
    }

  ${({ theme }) => theme.mq.xl} {
    margin-left: 0 auto;
    
    ul {
      display: flex;
      
      li {
        margin: 0 30px;
      }
    }
  }
`