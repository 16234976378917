import semipermanentimg from '../assets/semipermanent.webp'
import styled from 'styled-components'

function SemiPermanent () {

  return (

    <>
      <TreatmentsWrapper>
        <TreatmentImage />
        <TreatmentContent>
          <p><strong>Microblading</strong> is a semi-permanent way to have longer lasting eyebrows. Pigment is placed to create strokes that mimic the look of natural hairs. Treatment is for anyone with no eyebrows or to fill in any gaps.</p>
          <span className='line'/>
          <PriceList>
            <div>
              <p>Microblading (hair strokes)</p>
              <p>£280</p>
            </div>
            <div>
              <p>Semi-Permanent (machine method hair strokes)</p>
              <p>£360</p>
            </div>
            <div>
              <p>Powder brows / Ombre brows</p>
              <p>£300</p>
            </div>
            <div>
              <p>Eyeliner</p>
              <p>£250</p>
            </div>
            <div>
              <p>Baby eyeliner</p>
              <p>£250</p>
            </div>
            <div>
              <p>Lip liner</p>
              <p>£250</p>
            </div>
            <div>
              <p>Lip blush</p>
              <p>£400</p>
            </div>
            <div>
              <p className='info'>Consultation needs to be booked to carry out medical check and test patch before treatment can be done. Deposit £50 (Will be deducted off treatment). All of the above prices include a top up carried out 6-8 weeks later.</p>
            </div>
          </PriceList>
        </TreatmentContent>
      </TreatmentsWrapper>
    </>

  )
}

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TreatmentImage = styled.img`
  width: 100%;
  height: 200px;
  margin: 1rem 0;
  background: url(${semipermanentimg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid ${({theme}) => theme.color.lightBeige};

  ${({theme}) => theme.mq.md}{
    height: 300px;
    background-position: top;
  }
`

const TreatmentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.5rem;
  padding: 0 1.5rem;
  background: ${({theme}) => theme.color.lightBeige};
  border: 1px solid ${({theme}) => theme.color.lightBeige};

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  span {

    &.line {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.1rem;
      margin: 2rem 0;
      background: ${({theme}) => theme.color.beige};
    }
  }

  ${({theme}) => theme.mq.md} {

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.lg}{
    margin-bottom: 0;
    padding: 2rem 5rem;
  }
`

const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 0;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -1rem 0;

    p {
      font-size: ${({ theme }) => theme.font.size.sm.paragraph};
      color: ${({ theme }) => theme.color.darkBeige};
      flex-basis: 60%;

      &:last-child {
        text-align: right;
        flex-basis: 40%;
      }

      &.info {
        text-align: left;
        flex-basis: 100%;
        font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
        color: ${({ theme }) => theme.color.beige};
      }

      span {

        &.from {
          margin: 0 1rem;
          color: ${({ theme }) => theme.color.beige};
        }
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    div {

      p {
        font-size: ${({ theme }) => theme.font.size.md.paragraph};

        &.info {
          font-size: ${({ theme }) => theme.font.size.md.smParagraph};
        }
      }
    }
  }
`

export default SemiPermanent;