export const theme = {
  color: {
    darkBeige: 'rgb(89, 77, 66)',
    beige: 'rgb(140, 125, 112)',
    lightBeige: 'rgb(217, 190, 167)',
    extraLightBeige: 'rgb(242, 223, 206)',
    white: 'rgb(255, 255, 255)',
    red: 'rgb(191, 4, 38)',
    black: 'rgb(38, 38, 38)',
    greyTransparency: 'rgba(217, 217, 217, 0.2)',
    whiteTransparency: 'rgba(255, 255, 255, 0.75)',
    whiteTransparency25: 'rgba(255, 255, 255, 0.25)',
    darkBeigeTransparency: 'rgba(89, 77, 66, 0.5)',
    beigeTransparency: 'rgba(140, 125, 112, 0.5)',
    lightBeigeTransparency75: 'rgba(217, 190, 167, 0.75)',
    lightBeigeTransparency25: 'rgba(217, 190, 167, 0.25)',
    blackTransparency: 'rgba(38, 38, 38, 0.75)',
    blackTransparency25: 'rgba(38, 38, 38, 0.25)',
  },

  font: {
    family: {
      poppins: "'Poppins', sans-serif",
      vincente: "'Vincente', serif"
    },
    size: {
      sm: {
        mHeading: '7.2rem',
        heading: '4.8rem',
        paragraph: '1.6rem',
        smParagraph: '1.4rem',
        xsParagraph: '1rem',
      },
      md: {
        mHeading: '8.4rem',
        heading: '5.4rem',
        paragraph: '2rem',
        smParagraph: '1.6rem',
        xsParagraph: '1.2rem',
      },
      lg: {
        mHeading: '9.6rem',
        heading: '6.4rem',
        paragraph: '2.4rem',
        smParagraph: '1.8rem',
        xsParagraph: '1.4rem',
      }
    }
  },

  mq: {
    xs: '@media (min-width: 371px)',
    sm: '@media (min-width: 576px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)',
    xl: '@media (min-width: 1200px)',
    xxl: '@media (min-width: 1400px)'
  }
}