import React, { useEffect, useRef } from 'react';

import About from './components/About';
import Contact from './components/Contact'
import Cookies from './components/Cookies'
import Footer from './components/Footer'
import GlobalStyle from './styles/globalStyles'
import { Helmet } from 'react-helmet';
import Hero from './components/Hero'
import Navigation from './components/Navigation'
import Pricing from './components/Pricing';
import ReactGA from 'react-ga';
import ScrollToTop from './components/ScrollToTop'
import { ThemeProvider } from 'styled-components';
import Values from './components/Values'
import Work from './components/Work'
import { hotjar } from 'react-hotjar';
import { theme } from './styles/theme';

function App () {
  
  useEffect(() => {
    initializeReactGA();
    initializeHotjar();
  }, []);

  function initializeReactGA() {
    ReactGA.initialize('G-WZ46ZZLL2F');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  function initializeHotjar() {
    hotjar.initialize(3434031, 6);
  }

  function hotJar() {
    // wykonaj akcję po kliknięciu
    hotjar.tagRecording();
    hotjar.tagScreen(['homepage']);
  }

  const homeRef = useRef(null);
  const aboutmeRef = useRef(null);
  const pricingRef = useRef(null);
  const myworkRef = useRef(null);
  const contactRef = useRef(null);

  const refs = {
    home: homeRef,
    aboutme: aboutmeRef,
    pricing: pricingRef,
    mywork: myworkRef,
    contact: contactRef,
  };

  const handleSectionClick = (event, sectionName) => {
    event.preventDefault();
    const sectionRef = refs[sectionName];
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  }

    return (
      <>
        <Helmet>
          <title>The Skin Clinic | Aesthetics Specialist</title>
          <meta name="description" content="The Skin Clinic offering advanced treatments such as Dermal fillers, Botox, Skin boosters, Microblading, IPL Hair Reduction, Skin Resurfacing and Microdermabrasion. Based in Edinburgh but clinics also carried out in Kirkintilloch, Ashington(England) and Keswick(England)." />
          <meta name="keywords" content="Aesthetics in Edinburgh, Aesthetics in Kirkintilloch, Aesthetics in Ashington, Hair Reduction in Edinburgh, Dermal Filler in Edinburgh, Botox in Edinburgh, Lip Fillers in Edinburgh, Dermal Fillers in Ashington, Dermal Fillers in Kirkintilloch, Facial Treatments in Edinburgh, Botox in Ashington, Botox in Kirkintilloch, Microblading in Edinburgh, Microblading in Ashington, Microblading in Kirkintilloch, Beauty salon in Edinburgh, Beauty salon in Glasgow, Beauty salon in Kirkintilloch, Beauty salon in Newcastle, Beuaty salon in Ashington, IPL Machine Treatments in Glasgow, IPL Machine Treatments in Edinburgh, IPL Machine Treatments in Newcastle, IPL Machine Treatments in Ashington, IPL Machine Treatments in Kirkintilloch, Anti-Wrinkle Injections in Edinburgh, Anti-Wrinkle Injections in Glasgow, Anti-Wrinkle Injections in Newcastle, Anti-Wrinkle Injections in Ashington, Anti-Wrinkle Injections in Kirkintilloch, Semi permanent makeup in Edinburgh, Semi permanent makeup in Glasgow, Semi permanent makeup in Newcastle, Semi permanent makeup in Ashington, Semi permanent makeup in Kirkintilloch, Aesthetics specialist in Edinburgh, Aesthetics specialist in Glasgow, Aesthetics specialist in Newcastle, Aesthetics specialist in Kirkintilloch, Aesthetics specialist in Ashington, Skin care in Edinburgh, Skin Clinic in Edinburgh, Skin care in Glasgow, Skin Clinic in Glasgow, Skin care in Ashington, Skin Clinic in Ashington, Skin care in Newcastle, Skin Clinic in Newcastle, Skin care in Kirkintilloch, Skin Clinic in Kirkintilloch" />
          <meta http-equiv="Cache-Control" content="max-age=31536000" />
        </Helmet>

        <GlobalStyle />
        <ThemeProvider theme={theme}>
          
          <Cookies />

          <Navigation handleSectionClick={handleSectionClick} onClick={hotJar}/>
          <ScrollToTop onClick={hotJar} />

          <div ref={homeRef}>
            <Hero onClick={hotJar}/>
          </div>

          <div ref={aboutmeRef}>
            <About onClick={hotJar}/>
          </div>

          <div ref={pricingRef}>
            <Pricing onClick={hotJar}/>
          </div>

          <Values onClick={hotJar}/>

          <div ref={myworkRef}>
            <Work onClick={hotJar}/>
          </div>

          <div ref={contactRef}>
            <Contact onClick={hotJar}/>
          </div>
          
          <Footer handleSectionClick={handleSectionClick} onClick={hotJar}/>

        </ThemeProvider>
      </>
    ); 
}
export default App;
