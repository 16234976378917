import React, {useLayoutEffect, useState} from 'react'

import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import styled from 'styled-components'

function Work() {

  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (

    <>
      <WorkWrapper>
        <WorkContent>
          <WorkText>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.5 : 0.7,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <h2>My Work</h2>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.6 : 0.8,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <p>On my <strong><Link to='https://www.instagram.com/ml_aesthetics_/'>Instagram</Link></strong> profile, I showcase my work including before and after photos of <strong>treatments</strong> I perform and descriptions. I encourage you to visit my profile and see the results that can be <strong>achieved</strong> thanks to my treatments.</p>
            </motion.div>
          </WorkText>

          <motion.div
            initial={{ opacity: 0, x: '-20vw'}}
            whileInView={{ opacity: 1, x: 0}}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
              ease: [0, 0.71, 0.2, 1.01]
          }}>
            <WorkBackground>            
              <WhiteDiv />              
              <BeigeDiv />

              <GreyDiv>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    delay: isMobile ? 0.2 : 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <Quote>                
                      <h2>"</h2>
                      <p>Beauty is eternity gazing at itself in a mirror.</p>
                      <p>~ Khalil Gibran</p>
                  </Quote>                  
                  <Buttons>
                    <Link to='https://www.instagram.com/ml_aesthetics_/'><button className='seemywork' type='button' name='seemywork'>See my work</button></Link>
                    <Link to ='tel:07732745283'><button className='callme' type='button' name='call'>Call me</button></Link>
                  </Buttons>
                </motion.div>
              </GreyDiv>                
              </WorkBackground>
              </motion.div>
        </WorkContent>
      </WorkWrapper>
    </>

  )
}

const WorkWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 3rem;
  overflow-x: hidden;

  ${({theme}) => theme.mq.md} {
    padding: 0 5rem;
  }

  ${({theme}) => theme.mq.xl} {
    padding: 0 7.5rem;
  }

  ${({theme}) => theme.mq.xxl} {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
`

const WorkContent = styled.div`
  margin-top: 5rem;  

  ${({theme}) => theme.mq.md}{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
  }

  ${({theme}) => theme.mq.xl} {
    display: block;
    width: 100%;
    margin-top: 10rem;
  }
`

const WorkText = styled.div`
  margin-bottom: 5rem;
  width: 100%;
  max-width: 1440px;

  h2 {
    font-size: ${({ theme }) => theme.font.size.sm.heading};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};

    a {
      color: ${({ theme }) => theme.color.beige};
    }
  }

  ${({theme}) => theme.mq.md}{
    
    h2 {
      font-size: ${({ theme }) => theme.font.size.md.heading};
    }

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.xl} {
    position: absolute;
    z-index: 10;

    h2 {
      font-size: ${({ theme }) => theme.font.size.lg.heading};
    }

    p {
      width: 40%;
    }
  }
`

const WorkBackground = styled.div`
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.mq.xl} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    width: 100%;
  }
`

const WhiteDiv = styled.div`
  display: none;

  ${({theme}) => theme.mq.xl} {
    display: flex;
    width: 100%;
    background-color: transparent;
    grid-area: 1 / 1 / 2 / 2;
  }
`

const BeigeDiv = styled.div`
  display: none;

  ${({theme}) => theme.mq.xl} {
    display: flex;
    width: 100%;
    background-color: ${({ theme }) => theme.color.extraLightBeige};
    grid-area: 1 / 2 / 2 / 3;
  }
`

const GreyDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({theme}) => theme.color.greyTransparency};

  ${({theme}) => theme.mq.md}{
    padding-left: 1rem;
  }

  ${({theme}) => theme.mq.xl} {
    display: block;
    grid-area: 1 / 3 / 2 / 5;
  }
`

const Quote = styled.div`
  padding: 0 3rem;
  text-align: center;
  width: 100%;

  h2 {
    font-size: ${({ theme }) => theme.font.size.sm.heading};
    font-style: italic;
    color: ${({ theme }) => theme.color.darkBeige};
  }

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    font-style: italic;
    font-weight: 500;
    color: ${({ theme }) => theme.color.beige};

    &:last-child {
      font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
      font-weight: 300;
    }
  }

  ${({theme}) => theme.mq.md}{

    h2 {
      font-size: ${({ theme }) => theme.font.size.md.heading};
      text-align: left;
    }

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};

      &:last-child {
        font-size: ${({ theme }) => theme.font.size.md.smParagraph};
      }
    }
  }

  ${({theme}) => theme.mq.xl} {

    h2 {
      font-size: ${({ theme }) => theme.font.size.lg.heading};
    }

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
      text-align: left;
      padding: 0 10rem;
      font-weight: 400;

      &:last-child {
        font-size: ${({ theme }) => theme.font.size.md.smParagraph};
        text-align: right;
      }
    }
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5rem 0;

  a {
    width: 90%;

    button {

      &.seemywork {
        width: 100%;
        margin: 1rem 0;
        padding: 2rem 1.5rem;
        background: transparent;
        border: 1px solid ${({theme}) => theme.color.beige};
        font-size: ${({theme}) => theme.font.size.sm.paragraph};
        font-weight: 500;
        color: ${({theme}) => theme.color.beige};
        cursor: pointer;
        transition: .5s;
  
        &:hover {
          background: ${({theme}) => theme.color.darkBeige};
          border: 1px solid ${({theme}) => theme.color.darkBeige};
          color: ${({theme}) => theme.color.white};
        }
      }
  
      &.callme {
        width: 100%;
        margin: 1rem 0;
        padding: 2rem 1.5rem;
        background: ${({theme}) => theme.color.beige};
        border: 1px solid ${({theme}) => theme.color.beige};
        font-size: ${({theme}) => theme.font.size.sm.paragraph};
        font-weight: 500;
        color: ${({theme}) => theme.color.white};
        cursor: pointer;
        transition: .5s;
  
        &:hover {
          background: ${({theme}) => theme.color.darkBeige};
          border: 1px solid ${({theme}) => theme.color.darkBeige};
        }
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    a {

      button {

        &.seemywork {
          font-size: ${({theme}) => theme.font.size.md.paragraph};
        }

        &.callme {
          font-size: ${({theme}) => theme.font.size.md.paragraph};
        }
      }
    }
  }

  ${({theme}) => theme.mq.xl} {
    flex-direction: row;
    justify-content: center;
    margin-top: 12rem;

    a {
      width: auto;
      margin: 0 1rem;
      
      button {

        &.seemywork {
          padding: 2rem 4.5rem;
        }

        &.callme {
          padding: 2rem 4.5rem;
        }
      }
    }
  }
`

export default Work