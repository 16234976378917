import CallBack from './CallBack'
import React from 'react'
import background from '../assets/hero.webp'
import { motion } from 'framer-motion'
import styled from 'styled-components'

function Hero () {

  return (
    <>
      <motion.main
        className="main__container"
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0}}
        transition={{duration: 1,ease: [0.6, -0.05, 0.01, 0.99]}}
      >

        <HeroWrapper>
          <HeroContent>
            <div>
              <HeroText>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <h1>Let your <span className='keyword'>beauty</span> shine.</h1>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.8,
                    delay: 0.7,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <CallBack />
                </motion.div>
              </HeroText>
            </div>
          </HeroContent>
        </HeroWrapper>

      </motion.main>
    </>
  )

}

const HeroWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
`

const HeroContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${({theme}) => theme.color.blackTransparency25};

  ${({theme}) => theme.mq.lg} {
    justify-content: flex-start;
  }

  ${({theme}) => theme.mq.xxl} {

    > div {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
    }
    
  }
`

const HeroText = styled.div`
  flex-direction: column;
  padding: 0 3rem;

  h1 {
    margin-bottom: 0;
    font-size: ${({theme}) => theme.font.size.sm.mHeading};
    color: ${({theme}) => theme.color.lightBeige};
    text-shadow: 4px 4px 6px rgba(66, 68, 90, 1);

    .keyword {
      color: ${({theme}) => theme.color.white};
    }
  }

  ${({theme}) => theme.mq.md} {
    
    h1 {
      font-size: ${({theme}) => theme.font.size.md.mHeading};
    }
  }

  ${({theme}) => theme.mq.lg} {
    padding: 0 5rem;

    h1 {
      text-align: left;
    }
  }

  ${({theme}) => theme.mq.xl} {
    padding: 0 7.5rem;

    h1 {
      font-size: ${({theme}) => theme.font.size.lg.mHeading};
    }
  }

`

export default Hero;