import { CgArrowLongRight } from 'react-icons/cg'
import antiwrinkleimg from '../assets/antiwrinkle.webp'
import styled from 'styled-components'

function AntiWrinkle () {

  return (

    <>
      <TreatmentsWrapper>
        <TreatmentImage />
        <TreatmentContent>
          <p><strong>Botulinum Toxin</strong> is a toxin that is injected into the muscle which causes it to temporarily relax. This then helps the appearance of lines and wrinkles. Results last <strong>3-6 months.</strong></p>
          <Bundle>
            <div>
              <p>1 Area</p>
              <p>£145</p>
            </div>
            <div>
              <p>2 Areas</p>
              <p>£185</p>
            </div>
            <div>
              <p>3 Areas</p>
              <p>£225</p>
            </div>
            <div>
              <p>Available for:</p>
              <ul>
                <li><CgArrowLongRight />Frown line</li>
                <li><CgArrowLongRight />Forehead</li>
                <li><CgArrowLongRight />Crowns feet</li>
              </ul>
            </div>
          </Bundle>
          <span className='line'/>
          <PriceList>
            <div>
              <p>Bunny lines</p>
              <p>£50</p>
            </div>
            <div>
              <p>Nose tip lift</p>
              <p>£50</p>
            </div>
            <div>
              <p>Lip lines (smokers lines)</p>
              <p>£100</p>
            </div>
            <div>
              <p>Gummy smile</p>
              <p>£100</p>
            </div>
            <div>
              <p>Coble chin</p>
              <p>£50</p>
            </div>
            <div>
              <p>Lip flip</p>
              <p>£100</p>
            </div>
            <div>
              <p>Jaw slimming / Teeth grinding</p>
              <p>£190</p>
            </div>
            <div>
              <p>Marionettes (downwards smile)</p>
              <p>£60</p>
            </div>
            <div>
              <p>Neck bands</p>
              <p><span className='from'>from</span> £150</p>
            </div>
            <div>
              <p>Hyperhydrosis (underarms sweating)</p>
              <p><span className='from'>from</span> £350</p>
            </div>
          </PriceList>
        </TreatmentContent>
      </TreatmentsWrapper>
    </>

  )
}

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TreatmentImage = styled.img`
  width: 100%;
  height: 200px;
  margin: 1rem 0;
  background: url(${antiwrinkleimg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid ${({theme}) => theme.color.lightBeige};

  ${({theme}) => theme.mq.md}{
    height: 300px;
  }
`

const TreatmentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.5rem;
  padding: 0 1.5rem;
  background: ${({theme}) => theme.color.lightBeige};
  border: 1px solid ${({theme}) => theme.color.lightBeige};

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  span {

    &.line {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.1rem;
      margin: 2rem 0;
      background: ${({theme}) => theme.color.beige};
    }
  }

  ${({theme}) => theme.mq.md} {

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.lg}{
    margin-bottom: 0;
    padding: 2rem 5rem;
  }
`

const Bundle = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -1rem 0;

    &:last-child {
      flex-direction: column;
      margin-top: 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0 2rem;
        
        svg {
          margin-right: 2rem;
          font-size: 16px;
          color: ${({ theme }) => theme.color.darkBeige};
        }

        li {
          margin: 0.5rem 0;
          font-size: ${({ theme }) => theme.font.size.sm.paragraph};
          color: ${({ theme }) => theme.color.darkBeige};
        }
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    div {

      &:last-child {
        
        ul {
  
          li {
            font-size: ${({ theme }) => theme.font.size.md.paragraph};
          }
        }
      }
    }
  }
`

const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 0;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -1rem 0;

    p {
      font-size: ${({ theme }) => theme.font.size.sm.paragraph};
      color: ${({ theme }) => theme.color.darkBeige};
      flex-basis: 60%;

      &:last-child {
        text-align: right;
        flex-basis: 40%;
      }

      span {

        &.from {
          margin: 0 1rem;
          color: ${({ theme }) => theme.color.beige};
        }
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    div {

      p {
        font-size: ${({ theme }) => theme.font.size.md.paragraph};
      }
    }
  }
`

export default AntiWrinkle;