import { MdEmail, MdLocationOn } from 'react-icons/md'

import { AiFillInstagram } from 'react-icons/ai'
import { BsTelephoneFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import React from 'react';
import { motion } from 'framer-motion'
import { pdfjs } from 'react-pdf';
import { saveAs } from 'file-saver';
import styled from 'styled-components'
import whitelogo from '../assets/whitelogo.webp'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Footer({handleSectionClick}) {

  const handleLinkClick = (event, sectionName) => {
    event.preventDefault();
    handleSectionClick(event, sectionName);
  };

  function handleDownloadClick() {
    fetch('./privacypolicy.pdf')
      .then(response => response.blob())
      .then(blob => saveAs(blob, 'privacypolicy.pdf'))
      .catch(error => console.log(error));
  }

  return (

    <>

      <FooterWrapper>
        <FooterContent>
          <motion.div
            initial={{ opacity: 0}}
            whileInView={{ opacity: 1}}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01]
          }}>
            <FooterTop>
              <img src={whitelogo} alt='Logo' />
              <Links>
                <p>MENU</p>
                <p><Link to='/' onClick={(event) => handleLinkClick(event, 'home')}>Home</Link></p>
                <p><Link to='/' onClick={(event) => handleLinkClick(event, 'aboutme')}>About me</Link></p>
                <p><Link to='/' onClick={(event) => handleLinkClick(event, 'pricing')}>Pricing</Link></p>
                <p><Link to='/' onClick={(event) => handleLinkClick(event, 'mywork')}>My work</Link></p>
                <p><Link to='/' onClick={(event) => handleLinkClick(event, 'contact')}>Contact</Link></p>
              </Links>
              <ContactDetails>
                <TelEmail>
                  <Link to='tel:07732745283'>
                    <div>
                      <BsTelephoneFill />
                      <p>07732 745283</p>
                    </div>
                  </Link>
                  <Link to='mailto:info@theskinclinic-clinic.co.uk'>
                    <div>
                      <MdEmail />
                      <p>info@theskinclinic-clinic.co.uk</p>
                    </div>
                  </Link>
                </TelEmail>
                <Locations>
                  <MdLocationOn />
                  <div>
                    <p><strong>Carse & Galbraith</strong></p>
                    <p>45 Broomhall Drive</p>
                    <p>Edinburgh</p>
                    <p>EH12 7QL</p>
                    <Link to='https://www.google.com/maps/place/Carse+%26+Galbraith+Hair/@55.9380042,-3.2938063,17z/data=!3m1!4b1!4m6!3m5!1s0x4887c746a42d3c45:0x2aae3675ecc7c038!8m2!3d55.9380012!4d-3.2916176!16s%2Fg%2F11k69l6n7m' rel="nofollow"><p>Get Direction</p></Link>
                  </div>
                  <div>
                    <p><strong>Isla Beauty</strong></p>
                    <p>1 Dalrymple Court</p>
                    <p>Townhead</p>
                    <p>Kirkintilloch</p>
                    <p>G66 3AA</p>
                    <Link to='https://www.google.com/maps/place/Isla+Beauty/@55.9353615,-4.1550304,17z/data=!3m1!4b1!4m6!3m5!1s0x4888474052ba39e7:0x80122ee1a07eb426!8m2!3d55.9353585!4d-4.1528417!16s%2Fg%2F11j0qz34nl' rel="nofollow"><p>Get Direction</p></Link>
                  </div>
                  <div>
                    <p><strong>Naturally Yours</strong></p>
                    <p>23 Woodhorn Road</p>
                    <p>Ashington</p>
                    <p>NE63 9AS</p>
                    <Link to='https://www.google.com/maps/place/Naturally+Yours/@55.1841358,-1.5652009,17z/data=!3m1!4b1!4m6!3m5!1s0x487e0dbabd5b808d:0x7311f8d075969699!8m2!3d55.1841328!4d-1.5630122!16s%2Fg%2F1v27v1h1' rel="nofollow"><p>Get Direction</p></Link>
                  </div>
                </Locations>
              </ContactDetails>
            </FooterTop>
            <span></span>
            <FooterBottom>
              <p>The Skin Clinic © 2023 | Designed by <Link to='tel:07368666803'><strong>Mateusz Ziewiec</strong></Link></p>
              <div>
                <AiFillInstagram />
                <span></span>
                <p><Link to='#' onClick={handleDownloadClick}>Privacy Policy</Link></p>
              </div>
            </FooterBottom>
          </motion.div>
        </FooterContent>
      
      </FooterWrapper>

    </>

  )
}

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 5rem 3rem;
  overflow-x: hidden;
  background: ${({theme}) => theme.color.darkBeige};
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 5px 0px inset;

  ${({theme}) => theme.mq.md} {
    padding: 0 5rem;
  }

  ${({theme}) => theme.mq.xl} {
    padding: 0 7.5rem;
  }
`

const FooterContent = styled.div`
  margin-top: 0rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0.1rem;
    margin: 2rem 0;
    background: ${({theme}) => theme.color.whiteTransparency};
  }

  ${({theme}) => theme.mq.md}{
    margin: 1rem 0;
  }

  ${({theme}) => theme.mq.xxl}{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 7.5rem;
  }
`

const FooterTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 150px;
  }

  ${({theme}) => theme.mq.md} {
    margin-top: 3rem;
  }

  ${({theme}) => theme.mq.lg} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    img {
      max-width: 200px;
    }
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  text-align: center;

  p {
    margin-top: 0rem;
    font-size: ${({theme}) => theme.font.size.sm.paragraph};
    font-weight: 600;
    color: ${({theme}) => theme.color.white};
  }

  a {
    font-size: ${({theme}) => theme.font.size.sm.smParagraph};
    font-weight: 300;
    color: ${({theme}) => theme.color.white};
    text-decoration: none;
  }

  ${({theme}) => theme.mq.md} {
    flex-direction: row;
    align-items: center;

    p {
      padding: 0 2rem;
    }
  }

  ${({theme}) => theme.mq.lg} {
    flex-direction: column;
    margin-top: 0;
    width: 25%;
  }
`

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;

  ${({theme}) => theme.mq.lg}{
    width: 50%;
  }
`

const TelEmail = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 1rem;
      font-size: 20px;
      color: ${({theme}) => theme.color.white};
    }

    p {
      font-size: ${({theme}) => theme.font.size.sm.smParagraph};
      font-weight: 300;
      color: ${({theme}) => theme.color.white};
    }
  }

  ${({theme}) => theme.mq.md} {
    flex-direction: row;
    justify-content: space-between;

    div {
      padding: 0 1rem;
    }
  }

  ${({theme}) => theme.mq.lg} {

    div {

      svg {
        margin-right: 2rem;
      }
    }
  }
`

const Locations = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;

  svg {
    display: none;
  }

  div {
    text-align: center;

    p {
      font-size: ${({theme}) => theme.font.size.sm.smParagraph};
      font-weight: 300;
      color: ${({theme}) => theme.color.white};
    }

    a {
      color: ${({theme}) => theme.color.whiteTransparency};
      opacity: 0.5;
    }

  }

  ${({theme}) => theme.mq.md} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    div {
      padding: 0 2rem;
    }
  }
`

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2rem;

  p {
    font-size: ${({theme}) => theme.font.size.sm.smParagraph};
    font-weight: 300;
    color: ${({theme}) => theme.color.white};
  }

  a {
    color: ${({theme}) => theme.color.whiteTransparency};
    opacity: 0.5;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      font-size: 24px;
      color: ${({theme}) => theme.color.white};
    }

    span {
      content: "";
      display: inline-block;
      width: 0.1rem;
      height: 75%;
      margin: 0 2rem;
      background: ${({theme}) => theme.color.whiteTransparency};
    }
  }

  ${({theme}) => theme.mq.md} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 2rem;

    div {

      span {
        height: 3rem;
      }
    }

  }

`

export default Footer