import Cookie from "js-cookie";
import styled from "styled-components";
import { useState } from "react";

function CookieConsent() {
  const [visible, setVisible] = useState(!Cookie.get("cookieAccepted"));

  const handleAccept = () => {
    Cookie.set("cookieAccepted", "true", { expires: 365 });
    setVisible(false);
  };

  const handleDecline = () => {
    Cookie.set("cookieAccepted", "false", { expires: 365 });
    setVisible(false);
  };

  return (

    <CookieConsentWrapper visible={visible}>
      <CookieConsentText>
        We use our own and third-party cookies to personalize content and to analyze web traffic.
      </CookieConsentText>
      <CookieConsentButtonsWrapper>
        <CookieConsentButton accept onClick={handleAccept}>
          Accept
        </CookieConsentButton>
        <CookieConsentButton onClick={handleDecline}>Decline</CookieConsentButton>
      </CookieConsentButtonsWrapper>
    </CookieConsentWrapper>
    
  );
};

const CookieConsentWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 30px 0;
  width: 100%;
  background-color: ${({theme}) => theme.color.extraLightBeige};
  z-index: 999;
  overflow-x: hidden;
`;

const CookieConsentText = styled.p`
  max-width: 80vw;
  text-align: center;
  font-family: ${({theme}) => theme.font.family.poppins};
  font-size: ${({theme}) => theme.font.size.sm.paragraph};
`;

const CookieConsentButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const CookieConsentButton = styled.button`
  background-color: ${({ accept }) => (accept ? "#4CAF50" : "#f44336")};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  font-size: ${({theme}) => theme.font.size.sm.paragraph};
  margin: 0 10px;
  cursor: pointer;
`;

export default CookieConsent;