import { BiTimeFive } from 'react-icons/bi'
import facialtreatmentsimg from '../assets/facials.webp'
import styled from 'styled-components'

function FacialTreatments () {

  return (

    <>
      <TreatmentsWrapper>
        <TreatmentImage />
        <TreatmentContent>
          <PriceList>
            <div>
              <p className='info'><strong>Microdermabrasion</strong></p>
            </div>
            <div>
              <p className='info'>£60 / <span className='from'>Course of 3</span> £160</p>
            </div>
            <div>
              <p className='info'><BiTimeFive /> 40 minutes</p>
            </div>
            <div>
              <p className='info'>Diamond peel Dermabrasion is the all round facial for everyone. It suits all skin types given instant results. It plumps and brightens, removes dead skin and helps the appearance of fine lines and wrinkles. This facial is a favourite! Relaxing but great results at the same time!</p>
            </div>
            <span className='line'/>
            <div>
              <p className='info'><strong>Dermaquest Facial</strong></p>
            </div>
            <div>
              <p className='info'>£60 / <span className='from'>Course of 3</span> £160</p>
            </div>
            <div>
              <p className='info'><BiTimeFive /> 40 minutes</p>
            </div>
            <div>
              <p className='info'>A lovely way to take time out of your day to relax and have fantastic skin at the end of it! We discuss all the clients. Needs to tailor facial that is right for your skin. Advice give</p>
            </div>
            <span className='line'/>
            <div>
              <p className='info'><strong>Dermaquest Skin Peels / Resurfacer</strong></p>
            </div>
            <div>
              <p className='info'>£75 / <span className='from'>Course of 3</span> £200</p>
            </div>
            <div>
              <p className='info'><BiTimeFive /> 30-50 minutes</p>
            </div>
            <div>
              <p className='info'>Products applied to skin to cause mild shedding. Fanatic for fine lines and wrinkles, Acne, pigmentation or just a general boast of the skin.</p>
            </div>
            <span className='line'/>
            <div>
              <p className='info'><strong>Dermaquest TCA Peel</strong></p>
            </div>
            <div>
              <p className='info'>£100 / <span className='from'>Course of 3</span> £275</p>
            </div>
            <div>
              <p className='info'><BiTimeFive /> 30-50 minutes</p>
            </div>
            <div>
              <p className='info'>Products applied to the skin to give intense peeling. Downtime 3-5 days after treatment. Fantastic for serve pigmentation, Acne scars or deeper lines and wrinkles.</p>
            </div>
            <span className='line'/>
            <div>
              <p className='info'><strong>Dermal Roller</strong></p>
            </div>
            <div>
              <p className='info'>£130 / <span className='from'>Course of 3</span> £350</p>
            </div>
            <div>
              <p className='info'><BiTimeFive /> 40 minutes</p>
            </div>
            <div>
              <p className='info'>The Pure Roller stimulates new collagen and elastin production through creating controlled injury to the skin. This procedure boosts the skins repairing system resulting in firmer, softer skin with a more refined texture.</p>
            </div>
            <div>
              <ul>
                <li>Reduce fine lines and wrinkles</li>
                <li>Fade scars and stretch marks</li>
                <li>Help heal sun damage</li>
                <li>Improve the appearance of cellulite</li>
              </ul>
            </div>
            <span className='line'/>
            <div>
              <p className='infoW'>Consultation required and test patch. During consultation I can help determine which facial would be best and which would achieve the clients expectations.</p>
            </div>
          </PriceList>
        </TreatmentContent>
      </TreatmentsWrapper>
    </>

  )
}

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TreatmentImage = styled.img`
  width: 100%;
  height: 200px;
  margin: 1rem 0;
  background: url(${facialtreatmentsimg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid ${({theme}) => theme.color.lightBeige};

  ${({theme}) => theme.mq.md}{
    height: 300px;
  }
`

const TreatmentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.5rem;
  padding: 0 1.5rem;
  background: ${({theme}) => theme.color.lightBeige};
  border: 1px solid ${({theme}) => theme.color.lightBeige};

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  span {

    &.line {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.1rem;
      margin: 2rem 0;
      background: ${({theme}) => theme.color.beige};
    }
  }

  ${({theme}) => theme.mq.md} {

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.lg}{
    margin-bottom: 0;
    padding: 2rem 5rem;
  }
`

const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 0;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -1rem 0;

    p {
      font-size: ${({ theme }) => theme.font.size.sm.paragraph};
      color: ${({ theme }) => theme.color.darkBeige};
      flex-basis: 60%;

      svg {
        margin-right: 1rem;
      }

      &:last-child {
        text-align: right;
        flex-basis: 40%;
      }

      &.info {
        text-align: left;
        flex-basis: 100%;
      }

      &.infoW {
        text-align: left;
        flex-basis: 100%;
        font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
        color: ${({ theme }) => theme.color.beige};
      }

      span {

        &.from {
          margin: 0 1rem;
          color: ${({ theme }) => theme.color.beige};
        }
      }
    }

    ul {

      li {
        font-size: ${({ theme }) => theme.font.size.sm.paragraph};
        color: ${({ theme }) => theme.color.darkBeige};
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    div {

      p {
        font-size: ${({ theme }) => theme.font.size.md.paragraph};

        &.infoW {
          font-size: ${({ theme }) => theme.font.size.md.smParagraph};
        }
      }

      ul {

        li {
          font-size: ${({ theme }) => theme.font.size.md.paragraph};
        }
      }
    }
  }
`

export default FacialTreatments;