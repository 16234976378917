import './fonts.css';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    font-size: 62.5%;
  }
  body {
    background: hsl(51, 100%, 99%);
    margin: 0;
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
  }

  h1, h2 {
    font-family: 'Vincente', cursive;
  }
`;
 
export default GlobalStyle;