import '../styles/pricing.css';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import React, { useLayoutEffect, useState } from "react";

import AntiWrinkle from '../data/AntiWrinkle'
import DermalFiller from '../data/DermalFiller'
import FacialTreatments from "../data/FacialTreatments";
import IPL from '../data/IPL'
import SemiPermanent from '../data/SemiPermanent'
import SkinBoosters from '../data/SkinBoosters'
import { motion } from 'framer-motion'
import styled from "styled-components";

function Pricing () {

  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const response = await fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    });
    if (response.ok) {
      alert('Thank you for your message. We will reply to you in the next 48 hours');
      setName('');
      setEmail('');
      setPhone('');
      setLocation('');
      setCategory('');
      setTreatments('');
    } else {
      alert('An error occured. Please correct your details!');
    }
  };

  const options = {
    "Anti-Wrinkle Injections": [
      "Bunny Lines", 
      "Nose Tip Lift", 
      "Lip Lines (Smokers Lines)", 
      'Gummy Smile', 
      'Coble Chin', 
      'Lip Flip', 
      'Jaw Slimming / Teeth Grinding', 
      'Marionettes (Downwards Smile)', 
      'Neck Bands', 
      'Hyperhidrosis (Underarms Sweating)'
    ],
    "Dermal Filler": [
      'Nasolabial Folds (Smile Lines)', 
      'Marionette Lines (Downwards Smile)', 
      'Smokers Lines', 'Cheeks', 'Jawline', 
      'Chin', 
      'Non Surgical Rhinoplasty (Nose)', 
      'Tear Trough (Under Eyes)'],
    "Facial Treatments": [
      "Microdermabrasion", 
      'Dermaquest Facial', 
      'Dermaquest Skin Peels/Resurfacer', 
      'Dermaquest TCA Peel', 
      'Dermal Roller'],
    "Semi-Permanent Makeup": [
      'Microblading (Hair Strokes)', 
      'Semi-Permanent (Machine Method Hair Strokes)', 
      'Powder Brows/Ombre Brows', 
      'Eyeliner', 
      'Baby Eyeliner', 
      'Lip Liner', 
      'Lip Blush'],
    "Other": [
      "Skin Booster",
      "Pure Roller", 
      "IPL Machine Treatments", 
      "Custom"],
  }

  const [category, setCategory] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState("");

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    const selectedTreatments = options[selectedCategory] || [];
    setCategory(selectedCategory);
    setTreatments(selectedTreatments);
    setSelectedTreatment("");
  };

  const handleTreatmentChange = (e) => {
    const selectedTreatment = e.target.value;
    setSelectedTreatment(selectedTreatment);
  };

  const [selected, setSelected] = useState(0);

  const handleButtonClick = (index) => {
    setSelected(index);
  }

  const components = [<AntiWrinkle />, <DermalFiller />, <SkinBoosters />, <FacialTreatments />, <SemiPermanent />, <IPL />];

  return (

    <>

      <PricingWrapper>
        <PricingContent>
          <div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.5 : 0.7,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <h2>Pricing</h2>
            </motion.div>

            <PricingWireframe>
              <motion.div
                initial={{ opacity: 0, x: '-20vw'}}
                whileInView={{ opacity: 1, x: 0}}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: isMobile ? 0.6 : 0.8,
                  ease: [0, 0.71, 0.2, 1.01]
              }}>
                <PricingMenu>
                  <ul>
                    <li className={selected === 0 ? 'active' : ''} onClick={() => handleButtonClick(0)}>Anti-Wrinkle Injections</li>
                    <li className={selected === 1 ? 'active' : ''} onClick={() => handleButtonClick(1)}>Dermal Filler</li>
                    <li className={selected === 2 ? 'active' : ''} onClick={() => handleButtonClick(2)}>Skin Boosters</li>
                    <li className={selected === 3 ? 'active' : ''} onClick={() => handleButtonClick(3)}>Facial Treatments</li>
                    <li className={selected === 4 ? 'active' : ''} onClick={() => handleButtonClick(4)}>Semi-Permanent Makeup</li>
                    <li className={selected === 5 ? 'active' : ''} onClick={() => handleButtonClick(5)}>IPL Machine Treatments</li>
                    <li>
                      <BookingForm2 
                        action="https://formspree.io/f/xnqybvrl"
                        method="POST"
                        onSubmit={handleSubmit}>

                        <p>Book an Appointment</p>

                        <FormGroup>
                          <label htmlFor="name">Name:</label>
                          <input
                            id="nameBookingForm"
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />

                          <label htmlFor="email">Email:</label>
                          <input
                            id="emailBookingForm"
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />

                          <label htmlFor="phone">Phone:</label>
                          <input
                            id="phoneBookingForm"
                            type="tel"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                        </FormGroup>

                        <FormGroup>
                          <label htmlFor="location">Preferred Location:</label>
                          <select
                            id="locationBookingForm"
                            name="location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                          >
                            <option value="" disabled>Choose Location</option>
                            <option value="Edinburgh">Edinburgh (EH12 7QL)</option>
                            <option value="Kirkintilloch">Kirkintilloch (G66 3AA)</option>
                            <option value="Ashington">Ashington (NE63 9AS)</option>
                          </select>

                          <label htmlFor="service">Treatment:</label>
                          <select
                            id="service-categoryBookingForm"
                            name="service-category"
                            value={category}
                            onChange={handleCategoryChange}
                            required
                          >
                            <option value="" disabled>Choose a Category</option>
                            {Object.keys(options).map((category) => (
                              <option key={category} value={category}>{category}</option>
                            ))}
                          </select>
                        
                          {treatments.length > 0 && (
                            <div>
                              <label htmlFor="treatment"></label>
                              <select id="service-treatment" name="service-treatment" value={selectedTreatment} onChange={handleTreatmentChange} required>
                                {treatments.map((treatment) => (
                                  <option key={treatment} value={treatment}>{treatment}</option>
                                ))}
                              </select>
                            </div>
                          )}
                        </FormGroup>

                        <button type="submit" name='send enquire'>Send Enquire</button>

                        <p><strong>Terms & Conditions</strong></p>
                        <p>All deposits are non refundable. Appointments must be changed 24 hours prior to appointment. Deposits will be lost and full payment might be required before another appointment can be made if less than 24 hours. All payments made for courses are non refundable and must be completed within 12 months unless agreed otherwise. Areas cannot be changed once agreed and paid for. Full medical must be given at time of consultation and if anything changes to medical history or medication must to advised before further treatment.</p>
                      </BookingForm2>
                    </li>
                  </ul>
                </PricingMenu>
              </motion.div>
              
                <TreatmentsContent>
                  <TransitionGroup>
                    <CSSTransition key={selected}>
                    <motion.div
                      initial={{ opacity: 0, x: '20vw'}}
                      whileInView={{ opacity: 1, x: 0}}
                      viewport={{ once: true }}
                      transition={{
                        duration: 0.5,
                        delay: isMobile ? 0.7 : 0.9,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}>
                        {components[selected]}
                      </motion.div>
                    </CSSTransition>
                  </TransitionGroup>
                </TreatmentsContent>
              
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1}}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: isMobile ? 0.8 : 1,
                  ease: [0, 0.71, 0.2, 1.01]
              }}>
                <BookingForm 
                  action="https://formspree.io/f/xnqybvrl"
                  method="POST"
                  onSubmit={handleSubmit}>

                  <p>Book an Appointment</p>

                  <FormGroup>
                    <label htmlFor="name">Name:</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />

                    <label htmlFor="email">Email:</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <label htmlFor="phone">Phone:</label>
                    <input
                      id="phone"
                      type="tel"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="location">Preferred Location:</label>
                    <select
                      id="location"
                      name="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                    >
                      <option value="" disabled>Choose Location</option>
                      <option value="Edinburgh">Edinburgh (EH12 7QL)</option>
                      <option value="Kirkintilloch">Kirkintilloch (G66 3AA)</option>
                      <option value="Ashington">Ashington (NE63 9AS)</option>
                    </select>

                    <label htmlFor="service">Treatment:</label>
                    <select
                      id="service-category"
                      name="service-category"
                      value={category}
                      onChange={handleCategoryChange}
                      required
                    >
                      <option value="" disabled>Choose a Category</option>
                      {Object.keys(options).map((category) => (
                        <option key={category} value={category}>{category}</option>
                      ))}
                    </select>
                  
                    {treatments.length > 0 && (
                      <div>
                        <label htmlFor="treatment"></label>
                        <select id="service-treatment" name="service-treatment" value={selectedTreatment} onChange={handleTreatmentChange} required>
                          {treatments.map((treatment) => (
                            <option key={treatment} value={treatment}>{treatment}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </FormGroup>

                  <button type="submit">Send Enquire</button>

                  <p><strong>Terms & Conditions</strong></p>
                  <p>All deposits are non refundable. Appointments must be changed 24 hours prior to appointment. Deposits will be lost and full payment might be required before another appointment can be made if less than 24 hours. All payments made for courses are non refundable and must be completed within 12 months unless agreed otherwise. Areas cannot be changed once agreed and paid for. Full medical must be given at time of consultation and if anything changes to medical history or medication must to advised before further treatment.</p>
                </BookingForm>
              </motion.div>
            </PricingWireframe>
          </div>
        </PricingContent>
      </PricingWrapper>
      
    </>

  )
}

const PricingWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10rem;
  padding: 5rem 3rem;
  overflow-x: hidden;
  background: ${({theme}) => theme.color.lightBeigeTransparency25};
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

  ${({theme}) => theme.mq.md} {
    padding: 5rem;
  }

  ${({theme}) => theme.mq.xl} {
    padding: 5rem 7.5rem;
    padding-bottom: 10rem;
  }
`

const PricingContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: hidden;

  h2 {
    font-size: ${({ theme }) => theme.font.size.sm.heading};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  ${({theme}) => theme.mq.xxl} {

    > div {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 7.5rem;
    }
  }

  ${({theme}) => theme.mq.md} {
    h2 {
      font-size: ${({ theme }) => theme.font.size.md.heading};
    }
  }

  ${({theme}) => theme.mq.xl} {
    h2 {
      font-size: ${({ theme }) => theme.font.size.lg.heading};
    }
  }
`

const PricingWireframe = styled.div`
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.mq.lg}{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: fit-content;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
`

const PricingMenu = styled.div`
  margin-bottom: 2rem;

  ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    
    li {
      margin: 0.5rem 0;
      padding: 1rem 1.5rem;
      background: ${({theme}) => theme.color.extraLightBeige};
      border: 1px solid ${({theme}) => theme.color.extraLightBeige};
      font-size: ${({ theme }) => theme.font.size.sm.paragraph};
      color: ${({ theme }) => theme.color.beige};
      transition: .5s;
      cursor: pointer;

      &.active {
        background: ${({theme}) => theme.color.lightBeige};
        border: 1px solid ${({theme}) => theme.color.lightBeige};
        font-weight: 600;
        color: ${({ theme }) => theme.color.darkBeige};
      }

      &:hover {
        background: ${({theme}) => theme.color.lightBeige};
        border: 1px solid ${({theme}) => theme.color.lightBeige};
        color: ${({ theme }) => theme.color.darkBeige};
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    ul {

      li {

        font-size: ${({ theme }) => theme.font.size.md.paragraph};
      }
    }
  }

  ${({theme}) => theme.mq.lg}{
    1 / 1 / 2 / 2;

    ul {

      li {

        &:last-child {

          &:hover {
            background: ${({theme}) => theme.color.extraLightBeige};
            border: 1px solid ${({theme}) => theme.color.extraLightBeige};
          }
        }
      }
    }
  }
`

const TreatmentsContent = styled.div`
  ${({theme}) => theme.mq.lg}{
    grid-area: 1 / 2 / 2 / 4;
  }
`

const BookingForm = styled.form`
  display: flex;
  flex-direction: column;
  background: ${({theme}) => theme.color.extraLightBeige};
  padding: 0 3rem;

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};
    margin: 3rem 0;
  }

  button {
    margin: 3rem 0;
    padding: 2rem 1.5rem;
    background: ${({theme}) => theme.color.beige};
    border: 1px solid ${({theme}) => theme.color.beige};
    font-size: ${({theme}) => theme.font.size.sm.paragraph};
    font-weight: 500;
    color: ${({theme}) => theme.color.white};
    cursor: pointer;
    transition: .5s;

    &:hover {
      background: ${({theme}) => theme.color.darkBeige};
      border: 1px solid ${({theme}) => theme.color.darkBeige};
    }
  }

  ${({theme}) => theme.mq.md} {
    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }

    button {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.lg}{
    display: none;
  }
`

const BookingForm2 = styled.form`
  display: none;

  ${({theme}) => theme.mq.lg}{
    display: flex;
    flex-direction: column;
    background: ${({theme}) => theme.color.extraLightBeige};
    padding: 0 3rem;

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
      color: ${({ theme }) => theme.color.darkBeige};
      margin: 3rem 0;
    }

    button {
      margin: 3rem 0;
      padding: 2rem 1.5rem;
      background: ${({theme}) => theme.color.beige};
      border: 1px solid ${({theme}) => theme.color.beige};
      font-size: ${({theme}) => theme.font.size.md.paragraph};
      font-weight: 500;
      color: ${({theme}) => theme.color.white};
      cursor: pointer;
      transition: .5s;

      &:hover {
        background: ${({theme}) => theme.color.darkBeige};
        border: 1px solid ${({theme}) => theme.color.darkBeige};
      }
    }
  }
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.color.beige};
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    font-weight: 400;
    color: ${({ theme }) => theme.color.darkBeige};

    &:focus {
      outline: 2px solid ${({theme}) => theme.color.beige};
      outline-offset: -5px;
    }
  }

  label {
    font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
    color: ${({ theme }) => theme.color.beige};
  }

  select {
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.color.beige};
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    font-weight: 400;
    color: ${({ theme }) => theme.color.darkBeige};

    &:focus {
      outline: 2px solid ${({theme}) => theme.color.beige};
      outline-offset: -5px;
    }

    option {
      background: ${({ theme }) => theme.color.lightBeige};
      color: ${({ theme }) => theme.color.darkBeige};
      padding: 1.5rem;
    }
  }

  ${({theme}) => theme.mq.md} {
    input {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }

    label {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }

    select {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }
`

export default Pricing