import skinboostersimg from '../assets/skinboosters.webp'
import styled from 'styled-components'

function SkinBoosters () {

  return (

    <>
      <TreatmentsWrapper>
        <TreatmentImage />
        <TreatmentContent>
          <p><strong>Skin Boosters</strong> are injections that help stimulate collagen and elastin to give more youthful appearance. It targets the source of anti-ageing and not just the symptoms.</p>
          <span className='line'/>
          <PriceList>
            <div>
              <p>Profhilo</p>
              <p>£450</p>
            </div>
            <div>
              <p className='info'>2 treatments 28 days apart.</p>
            </div>
            <div>
              <p className='info'>Results then last up to 6 months</p>
            </div>
            <div>
              <p>Lumi eyes</p>
              <p>£145</p>
            </div>
            <div>
              <p className='info'>£385 (3 treatments).</p>
            </div>
            <div>
              <p className='info'>Lumi eyes is a skin boosting injectable treatment that brightens and rejuvenates the under eyes. Its smoothing and lifting helping with fine lines, stimulates collagen & elastin, stimulates skin to self-regenerate and diminishes dark circles. There is no downtime for this treatment and results are immediate.</p>
            </div>
          </PriceList>
        </TreatmentContent>
      </TreatmentsWrapper>
    </>

  )
}

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TreatmentImage = styled.img`
  width: 100%;
  height: 200px;
  margin: 1rem 0;
  background: url(${skinboostersimg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid ${({theme}) => theme.color.lightBeige};

  ${({theme}) => theme.mq.md}{
    height: 300px;
  }
`

const TreatmentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.5rem;
  padding: 0 1.5rem;
  background: ${({theme}) => theme.color.lightBeige};
  border: 1px solid ${({theme}) => theme.color.lightBeige};

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  span {

    &.line {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.1rem;
      margin: 2rem 0;
      background: ${({theme}) => theme.color.beige};
    }
  }

  ${({theme}) => theme.mq.md} {

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.lg}{
    margin-bottom: 0;
    padding: 2rem 5rem;
  }
`

const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 0;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -1rem 0;

    p {
      font-size: ${({ theme }) => theme.font.size.sm.paragraph};
      color: ${({ theme }) => theme.color.darkBeige};
      flex-basis: 60%;

      &:last-child {
        text-align: right;
        flex-basis: 40%;
      }

      &.info {
        text-align: left;
        flex-basis: 100%;
        font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
        color: ${({ theme }) => theme.color.beige};
      }

      span {

        &.from {
          margin: 0 1rem;
          color: ${({ theme }) => theme.color.beige};
        }
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    div {

      p {
        font-size: ${({ theme }) => theme.font.size.md.paragraph};

        &.info {
          font-size: ${({ theme }) => theme.font.size.md.smParagraph};
        }
      }
    }
  }
`

export default SkinBoosters;