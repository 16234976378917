import React, {useLayoutEffect, useState} from 'react'

import { Link } from 'react-router-dom'
import about1 from '../assets/about1.webp'
import about2 from '../assets/about2.webp'
import dermaquest from '../assets/dermaquest.webp'
import kairax from '../assets/kairax.webp'
import lynton from '../assets/lynton.webp'
import { motion } from 'framer-motion';
import profhillo from '../assets/profhillo.webp'
import revolax from '../assets/revolax.webp'
import styled from 'styled-components';
import teosyal from '../assets/teosyal.webp'

function About () {

  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

      <AboutWrapper>
        <AboutContent>
          <AboutText>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.5 : 0.7,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <h2>About me</h2>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.6 : 0.8,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <p><strong>The Skin Clinic</strong> offers a wide range of treatments which looks to cover all of your needs. From skin complaints to <strong>Aesthetics</strong> to soften/remove those lines and wrinkles. <strong>Microblading</strong> to help give some TLC to your brows. With <strong>over 20 years experience</strong> within the industry covering the UK. Clinics now carried out in <strong>Edinburgh, Glasgow (Kirkintilloch), Newcastle (Ashington)</strong> and the <strong>Lake District (Keswick)</strong>.</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.7 : 1,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <p><Link to='https://www.instagram.com/ml_aesthetics_/'>see my work</Link></p>
            </motion.div>
          </AboutText>

          <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
            <AboutBackground>
                <WhiteDiv />
                <BeigeDiv />

              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1}}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: isMobile ? 0.2 : 0.5,
                  ease: [0, 0.71, 0.2, 1.01]
              }}>
                <PhotoDiv>
                  <img src={about1} alt='Jennifer Anderson' />
                </PhotoDiv>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1}}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: isMobile ? 0.5 : 0.7,
                  ease: [0, 0.71, 0.2, 1.01]
              }}>
                <PhotoDiv>
                  <img src={about2} alt='Jennifer Anderson' />
                </PhotoDiv>
              </motion.div>
            </AboutBackground>
          </motion.div>
          
          <AboutLogos>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.3 : 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <img src={dermaquest} alt='Logo Dermaquest' />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.4 : 0.6,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <img src={kairax} alt='Logo Kairax' />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.5 : 0.7,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <img src={lynton} alt='Logo Lynton' />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.6 : 0.8,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <img src={profhillo} alt='Logo Profhillo' />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.7 : 0.9,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <img src={revolax} alt='Logo Revolax' />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.8 : 1,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <img src={teosyal} alt='Logo Teosyal' />
            </motion.div>
          </AboutLogos>
        </AboutContent>
      </AboutWrapper>

    </>
  )
}

const AboutWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 3rem;
  overflow-x: hidden;

  ${({theme}) => theme.mq.md} {
    padding: 0 5rem;
  }

  ${({theme}) => theme.mq.xl} {
    padding: 0 7.5rem;
  }

  ${({theme}) => theme.mq.xxl} {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
`

const AboutContent = styled.div`
  margin-top: 5rem;  

  ${({theme}) => theme.mq.xl} {

    margin-top: 10rem;
  }
`

const AboutText = styled.div`
  margin-bottom: 5rem;
  width: 100%;
  max-width: 1440px;
  
  h2 {
    font-size: ${({ theme }) => theme.font.size.sm.heading};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};

    a {
      color: ${({ theme }) => theme.color.beige};
    }
  }

  ${({theme}) => theme.mq.md} {
    
    h2 {
      font-size: ${({ theme }) => theme.font.size.md.heading};
    }

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.xl}{
    position: absolute;

    h2 {
      font-size: ${({ theme }) => theme.font.size.lg.heading};
    }

    p {
      width: 40%;
    }
  }
`

const AboutBackground = styled.div`
  display: flex;
  flex-direction: column;

  ${({theme}) => theme.mq.md} {
    flex-direction: row;
    justify-content: center;
  }

  ${({theme}) => theme.mq.xl}{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: px;
    width: 100%;
  }

`

const WhiteDiv = styled.div`
  display: none;

  ${({theme}) => theme.mq.xl} {
    display: flex;
    width: 100%;
    background-color: transparent;
    grid-area: 1 / 1 / 2 / 2;
  }
`

const BeigeDiv = styled.div`
  display: none;

  ${({theme}) => theme.mq.xl} {
    display: flex;
    width: 100%;
    background-color: ${({ theme }) => theme.color.extraLightBeige};
    grid-area: 1 / 2 / 2 / 3;
  }
`

const PhotoDiv = styled.div`
  width: 100%;
  margin: 1rem 0;

  img {
    width: 100%;
    height: 100%;
  }

  ${({theme}) => theme.mq.md} {
    height: 500px;
    padding: 0 0.5rem;
  }

  ${({theme}) => theme.mq.xl} {
    grid-area: 1 / 3 / 2 / 4;
    margin: 0;
    height: 550px;

    &:last-child {
      grid-area: 1 / 4 / 2 / 5;
      margin: 0;
    }
  }
`

const AboutLogos = styled.div`
  display: none;

  ${({theme}) => theme.mq.md} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 200px;
    margin-top: 2.5rem;
    opacity: 0.2;

    img {
      width: 100%;
      max-width: 100px;
    }
  }

  ${({theme}) => theme.mq.xl}{

    margin-top: 10rem;
    
    img {
      max-width: 150px;
    }
  }
`

export default About;