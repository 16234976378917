import React, {useState} from 'react'

import styled from 'styled-components'

function CallBack () {

  const [phone, setPhone] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const response = await fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    });
    if (response.ok) {
      alert('Thank you for your request callback. We will call back in the next 48 hours');
      setPhone('');
    } else {
      alert('An error occured. Please correct your details!');
    }
  };

  return (

    <CallBackDiv>
      <p>Hi there, leave your phone number we'll call you back</p>
      <form 
      action="https://formspree.io/f/mqkogezw"
      method="POST"
      onSubmit={handleSubmit}>
        <input id="phoneCallback"
              type="tel"
              name="phoneCallback"
              placeholder='Phone Number'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required />
        <button type='submit' name='submit'>Submit</button>
      </form>
    </CallBackDiv>

  )
}

const CallBackDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  p {
    font-size: ${({theme}) => theme.font.size.sm.paragraph};
    font-weight: 400;
    color: ${({theme}) => theme.color.white};
  }

  form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    input {
      padding: 2rem 1.5rem;
      background: transparent;
      border: 1px solid ${({theme}) => theme.color.white};
      font-size: ${({theme}) => theme.font.size.sm.paragraph};
      font-weight: 400;
      color: ${({theme}) => theme.color.white};

      &::placeholder {
        font-size: ${({theme}) => theme.font.size.sm.smParagraph};
        font-weight: 200;
        color: ${({theme}) => theme.color.whiteTransparency};
      }

      &:focus {
        outline: 2px solid ${({theme}) => theme.color.white};
        outline-offset: -5px;
      }
    }

    button {
      margin: 1rem 0;
      padding: 2rem 1.5rem;
      background: ${({theme}) => theme.color.darkBeige};
      border: 1px solid ${({theme}) => theme.color.darkBeige};
      font-size: ${({theme}) => theme.font.size.sm.paragraph};
      font-weight: 500;
      color: ${({theme}) => theme.color.white};
      cursor: pointer;
      transition: .5s;

      &:hover {
        background: ${({theme}) => theme.color.beige};
        border: 1px solid ${({theme}) => theme.color.beige};
      }
    }
  }

${({theme}) => theme.mq.md} {

    p {
      font-size: ${({theme}) => theme.font.size.md.paragraph};
    }

    form {

      input {
        font-size: ${({theme}) => theme.font.size.md.paragraph};
  
        &::placeholder {
          font-size: ${({theme}) => theme.font.size.md.smParagraph};
        }
      }

      button {
        font-size: ${({theme}) => theme.font.size.md.paragraph};
      }
    }
  }

  ${({theme}) => theme.mq.lg} {

    p {
      color: ${({theme}) => theme.color.darkBeige};
    }

    form {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      input {
        width: 100%;
        padding: 1.5rem;
        color: ${({theme}) => theme.color.darkBeige};
        border: 1px solid ${({theme}) => theme.color.darkBeige};

        &::placeholder {
          color: ${({theme}) => theme.color.darkBeigeTransparency};
        }

        &:focus {
          outline: 2px solid ${({theme}) => theme.color.darkBeige};
          outline-offset: -5px;
        }
      }

      button {
        padding: 1.5rem 5rem;
        border-bottom-right-radius: 100px;
      }
    }
  }

  ${({theme}) => theme.mq.xl} {

    form {
      
      input {
        padding: 2rem 2.5rem;
      }

      button {
        padding: 2rem 7rem;
      }
    }
  }

  ${({theme}) => theme.mq.xxl} {

    p {
      text-align: left;
    }

    form {
      width: 50%;
    }
  }

  
`

export default CallBack