import dermalfillerimg from '../assets/dermalfiller.webp'
import styled from 'styled-components'

function DermalFiller () {

  return (

    <>
      <TreatmentsWrapper>
        <TreatmentImage />
        <TreatmentContent>
          <p><strong>Dermal Filler</strong> is hyaluronic acid that is injected into to skin to help smooth out lines and wrinkles. It can add volume to areas were it has been lost and also used for facial augmentation.</p>
          <Bundle>
            <div>
              <p>Lips 0.5ml</p>
              <p>£160</p>
            </div>
            <div>
              <p>Lips 1.1ml</p>
              <p>£200</p>
            </div>
            <div>
              <p>Russians</p>
              <p>£250</p>
            </div>
          </Bundle>
          <span className='line'/>
          <PriceList>
            <div>
              <p>Nasolabial folds (smile lines)</p>
              <p><span className='from'>from</span> £250</p>
            </div>
            <div>
              <p>Marionette lines (downwards smile)</p>
              <p><span className='from'>from</span> £250</p>
            </div>
            <div>
              <p>Lip lines (smokers lines)</p>
              <p>£150</p>
            </div>
            <div>
              <p>Smokers lines</p>
              <p>£160</p>
            </div>
            <div>
              <p>Cheeks</p>
              <p><span className='from'>from</span> £250</p>
            </div>
            <div>
              <p>Jawline</p>
              <p><span className='from'>from</span> £250</p>
            </div>
            <div>
              <p>Chin</p>
              <p><span className='from'>from</span> £160</p>
            </div>
            <div>
              <p>Non surgical rhinoplasty (nose)</p>
              <p><span className='from'>from</span> £250</p>
            </div>
            <div>
              <p>Tear trough (under eyes)</p>
              <p>£250</p>
            </div>
          </PriceList>
        </TreatmentContent>
      </TreatmentsWrapper>
    </>

  )
}

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TreatmentImage = styled.img`
  width: 100%;
  height: 200px;
  margin: 1rem 0;
  background: url(${dermalfillerimg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid ${({theme}) => theme.color.lightBeige};

  ${({theme}) => theme.mq.md}{
    height: 300px;
  }
`

const TreatmentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.5rem;
  padding: 0 1.5rem;
  background: ${({theme}) => theme.color.lightBeige};
  border: 1px solid ${({theme}) => theme.color.lightBeige};

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  span {

    &.line {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.1rem;
      margin: 2rem 0;
      background: ${({theme}) => theme.color.beige};
    }
  }

  ${({theme}) => theme.mq.md} {

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.lg}{
    margin-bottom: 0;
    padding: 2rem 5rem;
  }
`

const Bundle = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -1rem 0;

  }
`

const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 0;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -1rem 0;

    p {
      font-size: ${({ theme }) => theme.font.size.sm.paragraph};
      color: ${({ theme }) => theme.color.darkBeige};
      flex-basis: 60%;

      &:last-child {
        text-align: right;
        flex-basis: 40%;
      }

      span {

        &.from {
          margin: 0 1rem;
          color: ${({ theme }) => theme.color.beige};
        }
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    div {

      p {
        font-size: ${({ theme }) => theme.font.size.md.paragraph};
      }
    }
  }
`

export default DermalFiller;