import iplimg from '../assets/IPL.webp'
import styled from 'styled-components'

function IPL () {

  return (

    <>
      <TreatmentsWrapper>
        <TreatmentImage />
        <TreatmentContent>
          <p><strong>IPL Hair Reduction</strong> is a treatment that permanently reduces the hair growth. The treatment is designed to destroy hair follicles so the hair can’t grow. Courses are needed for this treatment.</p>
          <span className='line'/>
          <PriceList>
            <div className='head'>
              <p>Hair Reduction</p>
              <p>per 1 / per 3 / per6</p>
            </div>
            <div>
              <p className='info'><span className='from'>( prices from )</span></p>
            </div>
            <div>
              <p>Lip</p>
              <p>£50 / £135 / £250</p>
            </div>
            <div>
              <p>Chin</p>
              <p>£50 / £135 / £250</p>
            </div>
            <div>
              <p>Neck</p>
              <p>£60 / £160 / £300</p>
            </div>
            <div>
              <p>Cheeks</p>
              <p>£65 / £175 / £325</p>
            </div>
            <div>
              <p>Full face</p>
              <p>£125 / £335 / £625</p>
            </div>
            <div>
              <p>Breasts</p>
              <p>£110 / £295 / £550</p>
            </div>
            <div>
              <p>Forearm</p>
              <p>£125 / £335 / £625</p>
            </div>
            <div>
              <p>Full arm</p>
              <p>£145 / £390 / £725</p>
            </div>
            <div>
              <p>Underarms</p>
              <p>£65 / £175 / £325</p>
            </div>
            <div>
              <p>Abdomen</p>
              <p>£60 / £160 / £300</p>
            </div>
            <div>
              <p>Standard bikini</p>
              <p>£65 / £175 / £325</p>
            </div>
            <div>
              <p>High bikini</p>
              <p>£70 / £185 / £350</p>
            </div>
            <div>
              <p>Half leg</p>
              <p>£165 / £445 / £825</p>
            </div>
            <div>
              <p>Half leg incl. knee</p>
              <p>£180 / £480 / £900</p>
            </div>
            <div>
              <p>Full legs</p>
              <p>£295 / £750 / £1475</p>
            </div>
            <span className='line'/>
            <div className='head'>
              <p>Mens</p>
              <p>per 1 / per 3 / per6</p>
            </div>
            <div>
              <p className='info'><span className='from'>( prices from )</span></p>
            </div>
            <div>
              <p>Back</p>
              <p>£180 / £495 / £900</p>
            </div>
            <div>
              <p>Chest</p>
              <p>£135 / £365 / £675</p>
            </div>
            <div>
              <p>Abdomen</p>
              <p>£135 / £365 / £675</p>
            </div>
            <div>
              <p>Neck (hairline)</p>
              <p>£60 / £160 / £300</p>
            </div>
            <span className='line'/>
            <div className='head'>
              <p>Photorejuvenation</p>
              <p>per 1 / per 3 / per6</p>
            </div>
            <div>
              <p className='info'><span className='from'>( prices from )</span></p>
            </div>
            <div>
              <p>Thread veins</p>
              <p>£60 / £160 / £300</p>
            </div>
            <div>
              <p>Pigmentation</p>
              <p>£60 / £160 / £300</p>
            </div>
            <div>
              <p>Rosacea</p>
              <p>£80 / £205 / £400</p>
            </div>
            <div>
              <p>Full face</p>
              <p>£100 / £250 / £500</p>
            </div>
            <div>
              <p>Rejuvenation facial</p>
              <p>£100 / £250 / £500</p>
            </div>
            <div>
              <p>Hands (age spots)</p>
              <p>£80 / £205 / £400</p>
            </div>
            <div>
              <p>Acne treatment</p>
              <p>£100 / £250 / £500</p>
            </div>
          </PriceList>
        </TreatmentContent>
      </TreatmentsWrapper>
    </>

  )
}

const TreatmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TreatmentImage = styled.img`
  width: 100%;
  height: 200px;
  margin: 1rem 0;
  background: url(${iplimg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 5px solid ${({theme}) => theme.color.lightBeige};

  ${({theme}) => theme.mq.md}{
    height: 300px;
  }
`

const TreatmentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.5rem;
  padding: 0 1.5rem;
  background: ${({theme}) => theme.color.lightBeige};
  border: 1px solid ${({theme}) => theme.color.lightBeige};

  p {
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  span {

    &.line {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0.1rem;
      margin: 2rem 0;
      background: ${({theme}) => theme.color.beige};
    }
  }

  ${({theme}) => theme.mq.md} {

    p {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.lg}{
    margin-bottom: 0;
    padding: 2rem 5rem;
  }
`

const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 0;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: -1rem 0;

    &.head {
      p {
        font-weight: 600;
      }
    }

    p {
      font-size: ${({ theme }) => theme.font.size.sm.paragraph};
      color: ${({ theme }) => theme.color.darkBeige};
      flex-basis: 30%;

      &:last-child {
        text-align: right;
        flex-basis: 70%;
      }

      &.info {
        text-align: left;
        flex-basis: 100%;
        font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
        color: ${({ theme }) => theme.color.beige};
      }

      span {

        &.from {
          color: ${({ theme }) => theme.color.beige};
        }

      }
    }

  }

  ${({theme}) => theme.mq.md} {

    div {

      p {
        font-size: ${({ theme }) => theme.font.size.md.paragraph};

        &.info {
          font-size: ${({ theme }) => theme.font.size.md.smParagraph};
        }
      }
    }
  }
`

export default IPL;