import { GiLaurelCrown, GiSyringe } from 'react-icons/gi'
import React, {useLayoutEffect, useState} from 'react'

import { FaMapMarkedAlt } from 'react-icons/fa'
import background from '../assets/valuesbg.webp'
import { motion } from 'framer-motion'
import styled from "styled-components";

function Values () {

  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <ValuesWrapper>
        <ValuesContent>
          <div>
            <ValuesDivs>
              <div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    delay: isMobile ? 0.3 : 0.6,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <div className='Icon'>
                    <GiLaurelCrown />
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    delay: isMobile ? 0.5 : 0.8,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <div className='Text'>
                    <p>20+</p>
                    <p>Years experience</p>
                  </div>
                </motion.div>
              </div>
              <div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    delay: isMobile ? 0.3 : 0.6,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <div className='Icon'>
                    <GiSyringe />
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    delay: isMobile ? 0.5 : 0.8,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <div className='Text'>
                    <p>50+</p>
                    <p>Treatments</p>
                  </div>
                </motion.div>
              </div>
              <div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    delay: isMobile ? 0.3 : 0.6,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <div className='Icon'>
                    <FaMapMarkedAlt />
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1}}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    delay: isMobile ? 0.5 : 0.8,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                  <div className='Text'>
                    <p>3</p>
                    <p>Locations</p>
                  </div>
                </motion.div>
              </div>
            </ValuesDivs>
          </div>
        </ValuesContent>
      </ValuesWrapper>
    </>
  )
}

const ValuesWrapper = styled.div`
  display: flex;
  width: 100%;
  background: url(${background});
  background-size: cover;
  background-position: center;
`

const ValuesContent = styled.div`
  width: 100%;
  background: ${({theme}) => theme.color.blackTransparency};
  padding: 3rem;

  ${({theme}) => theme.mq.xxl} {

    > div {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
    }
    
  }
`

const ValuesDivs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0;

    .Icon {
      margin-bottom: 0;
      font-size: 80px;
      color: ${({theme}) => theme.color.extraLightBeige};
    }

    .Text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left;
      margin-top: -1rem;

      p {
        margin: -0.8rem 0;
        font-size: ${({theme}) => theme.font.size.sm.heading};
        font-weight: 700;
        color: ${({theme}) => theme.color.extraLightBeige};

        &:last-child {
          font-size: ${({theme}) => theme.font.size.sm.paragraph};
          font-weight: 400;
          color: ${({theme}) => theme.color.lightBeige};
        }
      }
    }
  }

  ${({theme}) => theme.mq.md} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: px;

    div {
      display: flex;
      flex-direction: row;

      .Icon {
        font-size: 64px;
        margin-right: 1.5rem;
        
      }

      .Text {
        display: block;
        text-align: left;
        margin-top: 3.5rem;

        p {
          margin: -1.5rem 0;
        }
      }
    }
  }

  ${({theme}) => theme.mq.xl} {


    div {

      .Icon {
        font-size: 96px;
      }

      .Text {

        p {
          font-size: ${({theme}) => theme.font.size.md.heading};

          &:last-child {
            font-size: ${({theme}) => theme.font.size.md.paragraph};
          }
        }
      }
    }
  }
`

export default Values;