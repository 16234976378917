import { MdEmail, MdLocationOn } from 'react-icons/md'
import React, { useLayoutEffect, useState } from 'react';

import { AiFillInstagram } from 'react-icons/ai'
import { BsTelephoneFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import styled from 'styled-components'

function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const response = await fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    });
    if (response.ok) {
      alert('Thank you for your message. We will reply to you in the next 48 hours');
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
    } else {
      alert('An error occured. Please correct your details!');
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <ContactWrapper>
        <ContactContent>
          <div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.5 : 0.7,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <h2>Contact</h2>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: '20vw'}}
              whileInView={{ opacity: 1, y: 0}}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: isMobile ? 0.6 : 0.7,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
              <div className='ContactContentDiv'>
                <ContactForm 
                  action="https://formspree.io/f/mbjeaybp" 
                  method="POST"
                  onSubmit={handleSubmit}>

                  <p>Contact Form</p>

                  <FormGroup>
                    <input type='text' name='name' id='nameContactForm' value={name} onChange={(e) => setName(e.target.value)} required/>
                    <label htmlFor='name'>Name</label>
                  </FormGroup>

                  <FormGroup>
                    <input type='phone' name='phone' id='phoneContactForm' value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                    <label htmlFor='tel'>Phone Number</label>
                  </FormGroup>

                  <FormGroup>
                    <input type='email' name='email' id='emailContactForm' value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    <label htmlFor='email'>Email</label>
                  </FormGroup>

                  <FormGroup>
                    <textarea name='message' id='messageContactForm' value={message} onChange={(e) => setMessage(e.target.value)} required/>
                    <label htmlFor='message'>Message</label>
                  </FormGroup>

                  <button type='submit' name='send'>Send</button>

                </ContactForm>
                <Locations>
                  <p>Locations</p>
                  <div>
                    <div>
                      <MdLocationOn />
                      <div>
                        <p><strong>Carse & Galbraith</strong></p>
                        <p>45 Broomhall Drive</p>
                        <p>Edinburgh</p>
                        <p>EH12 7QL</p>
                        <Link to='https://www.google.com/maps/place/Carse+%26+Galbraith+Hair/@55.9380042,-3.2938063,17z/data=!3m1!4b1!4m6!3m5!1s0x4887c746a42d3c45:0x2aae3675ecc7c038!8m2!3d55.9380012!4d-3.2916176!16s%2Fg%2F11k69l6n7m' rel="nofollow"><p>Get Direction</p></Link>
                      </div>
                    </div>
                    <div>
                      <MdLocationOn />
                      <div>
                        <p><strong>Isla Beauty</strong></p>
                        <p>1 Dalrymple Court</p>
                        <p>Townhead</p>
                        <p>Kirkintilloch</p>
                        <p>G66 3AA</p>
                        <Link to='https://www.google.com/maps/place/Isla+Beauty/@55.9353615,-4.1550304,17z/data=!3m1!4b1!4m6!3m5!1s0x4888474052ba39e7:0x80122ee1a07eb426!8m2!3d55.9353585!4d-4.1528417!16s%2Fg%2F11j0qz34nl' rel="nofollow"><p>Get Direction</p></Link>
                      </div>
                    </div>
                    <div>
                      <MdLocationOn />
                      <div>
                        <p><strong>Naturally Yours</strong></p>
                        <p>23 Woodhorn Road</p>
                        <p>Ashington</p>
                        <p>NE63 9AS</p>
                        <Link to='https://www.google.com/maps/place/Naturally+Yours/@55.1841358,-1.5652009,17z/data=!3m1!4b1!4m6!3m5!1s0x487e0dbabd5b808d:0x7311f8d075969699!8m2!3d55.1841328!4d-1.5630122!16s%2Fg%2F1v27v1h1' rel="nofollow"><p>Get Direction</p></Link>
                      </div>
                    </div>
                  </div>
                </Locations>
                <Details>
                  <div>
                    <p>Call me</p>
                    <Link to='tel:07732745283'>
                      <div>
                        <BsTelephoneFill />
                        <p>07732 745283</p>
                      </div>
                    </Link>
                    <p>Send me email</p>
                    <Link to='mailto:info@theskinclinic-clinic.co.uk'>
                      <div>
                        <MdEmail />
                        <p>info@theskinclinic-clinic.co.uk</p>
                      </div>
                    </Link>
                    <p>Follow me</p>
                    <Link to='https://www.instagram.com/ml_aesthetics_/'>
                      <div>
                        <AiFillInstagram />
                        <p>Instagram</p>
                      </div>
                    </Link>
                  </div>
                </Details>
              </div>
            </motion.div>
          </div>
          
        </ContactContent>
      </ContactWrapper>
    </>
  )
}

const ContactWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10rem;
  padding: 0 3rem;
  padding-bottom: 10rem;
  overflow-x: hidden;
  background: ${({theme}) => theme.color.extraLightBeige};
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

  ${({theme}) => theme.mq.md} {
    padding: 0 5rem;
    padding-bottom: 10rem;
  }

  ${({theme}) => theme.mq.xl} {
    padding: 0 7.5rem;
  }
  
`

const ContactContent = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: hidden;

  h2 {
    font-size: ${({ theme }) => theme.font.size.sm.heading};
    color: ${({ theme }) => theme.color.darkBeige};
  }

  div {

    &.ContactContentDiv {
      display: flex;
      flex-direction: column;
    }
  }

  ${({theme}) => theme.mq.md} {

    h2 {
      font-size: ${({ theme }) => theme.font.size.md.heading};
    }
  }

  ${({theme}) => theme.mq.xl}{

    padding-bottom: 10rem;

    h2 {
      font-size: ${({ theme }) => theme.font.size.lg.heading};
    }

    div {

      .ContactContentDiv {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }
    }
  }

  ${({theme}) => theme.mq.xxl} {

    > div {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 7.5rem;
    }
    
  }
`

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;  
  order: 2;
  margin-top: 5rem;

  p {
    align-self: flex-start;
    margin-bottom: -1rem;
    font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
    color: ${({ theme }) => theme.color.beige};
  }

  button {
    margin-top: 1.5rem;
    padding: 2rem 1.5rem;
    background: ${({theme}) => theme.color.beige};
    border: 1px solid ${({theme}) => theme.color.beige};
    font-size: ${({theme}) => theme.font.size.sm.paragraph};
    font-weight: 500;
    color: ${({theme}) => theme.color.white};
    cursor: pointer;
    transition: .5s;

    &:hover {
      background: ${({theme}) => theme.color.darkBeige};
      border: 1px solid ${({theme}) => theme.color.darkBeige};
    }
  }

  ${({theme}) => theme.mq.md} {

    p {
      font-size: ${({ theme }) => theme.font.size.md.smParagraph};
    }

    button {
      font-size: ${({theme}) => theme.font.size.md.paragraph};
      font-weight: 500;
    }
  }

  ${({theme}) => theme.mq.xl} {
    width: 100%;
    margin-top: 1rem;
    grid-area: 1 / 1 / 3 / 2;
    
  }
`

const FormGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  input {

    width: 100%;
    margin-top: 1.5rem;
    padding: 1.5rem;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.color.beige};
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    font-weight: 400;
    color: ${({ theme }) => theme.color.darkBeige};
    z-index: 10;
    transition: .5s;

    &:focus {
      outline: 2px solid ${({theme}) => theme.color.beige};
      outline-offset: -5px;
      margin-top: 4.5rem;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    margin-top: 1.5rem;
    padding: 1.5rem;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.color.beige};
    font-family: ${({ theme }) => theme.font.family.poppins};
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.darkBeige};
    font-weight: 400;
    transition: .5s;

    &:focus {
      outline: 2px solid ${({theme}) => theme.color.beige};
      outline-offset: -5px;
      margin-top: 4.5rem;
    }
  }

  label {
    position: absolute;
    margin-top: 2.7rem;
    margin-left: 1.5rem;
    font-size: ${({ theme }) => theme.font.size.sm.paragraph};
    color: ${({ theme }) => theme.color.lightBeige};
    transition: .5s;
  }

  input:focus ~ label, input:valid ~ label, textarea:focus ~ label, textarea:valid ~ label {
    transform: translateX(-1.5rem) translateY(-0.7rem);
    color: ${({ theme }) => theme.color.beige};
    font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
  }

  input:valid, textarea:valid {
    margin-top: 4.5rem;
  }

  ${({theme}) => theme.mq.md}{

    input {
      padding: 2rem;
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }

    textarea {
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }

    label {
      padding-top: 0.5rem;
      padding-left: 0.5rem;
      font-size: ${({ theme }) => theme.font.size.md.paragraph};
    }
  }

  ${({theme}) => theme.mq.xl} {

    textarea {
      height: 195px;
    }
  }
`

const Locations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  order: 3;

  p {
    align-self: flex-start;
    margin-bottom: -1.5rem;
    font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
    color: ${({ theme }) => theme.color.beige};
  }

  div {
    width: 100%;

    div {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      padding: 1rem 1.5rem;
      border: 1px solid ${({ theme }) => theme.color.beige};
      width: 100%;
  
      svg {
        display: none;
      }
  
      div {
        display: flex;
        flex-direction: column;
        border: none;
  
        p {
          margin-top: -1rem;
          margin-bottom: 1.25rem;
          font-size: ${({ theme }) => theme.font.size.sm.paragraph};
          color: ${({ theme }) => theme.color.darkBeige};
  
          &:last-child {
            margin-top: 1rem;
          }
        }
  
        a {
          color: ${({ theme }) => theme.color.beige};
          margin-top: 1rem;
  
          p {
            color: ${({ theme }) => theme.color.beige};
          }
        }
      }
    }
  }

  ${({theme}) => theme.mq.md} {

    p {
      font-size: ${({ theme }) => theme.font.size.md.smParagraph};
    }

    div {
      display: flex;

      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem;
        
        &:nth-child(2) {
          margin: 2rem 2rem 0 2rem;
        }

      }
    }
  }

  ${({theme}) => theme.mq.xl} {
    width: 100%;
    margin-top: 0;
    grid-area: 2 / 2 / 3 / 3;
  }

`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.5rem;
  order: 1;

  p {
    align-self: flex-start;
    margin-bottom: -1.5rem;
    font-size: ${({ theme }) => theme.font.size.sm.smParagraph};
    color: ${({ theme }) => theme.color.beige};
  }

  a {
    color: ${({ theme }) => theme.color.darkBeige};
    text-decoration: none;
  }

  div {

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 2rem;
      padding: 2rem 1.5rem;
      background: ${({theme}) => theme.color.beige};
      border: 1px solid ${({ theme }) => theme.color.beige};
      transition: .5s;

      &:hover {
        background: ${({theme}) => theme.color.darkBeige};
        border: 1px solid ${({theme}) => theme.color.darkBeige};
      }
  
      svg {
        margin-right: 1.5rem;
        font-size: 24px;
        color: ${({theme}) => theme.color.white};
      }
  
      p {
        margin-top: 0;
        padding-bottom: 1.2rem;
        color: ${({ theme }) => theme.color.darkBeige};
        font-size: ${({ theme }) => theme.font.size.sm.paragraph};
        color: ${({theme}) => theme.color.white};
      }
    }
  }

  

  ${({theme}) => theme.mq.md}{

    p {
      font-size: ${({ theme }) => theme.font.size.md.smParagraph};
    }

    div {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      margin-left: 0;

      div {

        svg {
          font-size: 36px;
        }

        p {
          padding-top: 0.3rem;
          font-weight: 400;
          font-size: ${({ theme }) => theme.font.size.md.paragraph};
        }
      }
  }

  ${({theme}) => theme.mq.xl} {
    margin-top: 1rem;
    grid-area: 1 / 2 / 2 / 3;

    div {

      div {
        padding: 2rem;
      }
    }
  }
  
`

export default Contact;